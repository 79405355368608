<template>
  <div class="production_wrap">
    <div class = "box">
      <div class="about-title">工程咨询所</div>
      <div class = "block-line"></div>
      <div class="about-container">
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <b><span style="font-size:16px;">工程咨询所主要职责：</span></b>
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">主要承担城市开发、更新和建设工程项目咨询中所涵盖的规划策划、产业研究、可行性研究、项目建议书、项目和资金申请报告等；同时承接拿地相关服务，包括区域价值评估、方案及定位研究、投入产出估算及后期项目管理等业务。</span> 
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;"><b>副所长：吕维真（负责人）</b></span> 
        </p>
      </div>
    </div>
    <CallMe />
    <MobileCallme />
  </div>
</template>

<script>
import CallMe from '@/components/callme/index'
import MobileCallme from '@/components/callme/MobileCallme.vue'
export default {
  data() {
    return {
    }
  },
  components: {
    CallMe,
    MobileCallme,
  },
}
</script>

<style lang="scss" scoped>
.production_wrap {
  padding-top: 200px;
  width: 100%;
}
</style>